<template>
    <div class="calendar_settings">
        <!-- <div class="header" v-if="isNewCalendar && calendarSettingsTitle">
            Calendar is created!
        </div> -->

		<!-- <div class="template__header">
			<div class="header__button" @click="goToTemplateMenu">
				<span class="iconify" data-icon="uil:times" data-width="25" data-height="25"></span>
			</div>
			<div class="header__title" >
				Template settings
			</div>
			<div class="header__button" @click="submit">
				<span class="iconify" data-icon="uil:check" style="color: #0075ff;" data-width="25" data-height="25"></span>
			</div>
		</div> -->


		<SettingsHeader v-if="calendarSettingsTitle"
		:title="calendarSettingsTitle"
		@on-click-left="goOut">
			<template v-slot:left-button v-if="!isNewCalendar">
				<span class="iconify"
					data-icon="material-symbols:arrow-left-alt-rounded" 
                    data-width="21" 
					data-height="21">
				</span>
			</template>
		</SettingsHeader>

        <transition :name="transitionName" mode="out-in">
			<router-view class="calendar-settings__content"></router-view>
        </transition>
	</div>
</template>

<script>
import router from '../router';

export default {
    name: 'CalendarSettings',
	components: {
		SettingsHeader: () => import("../components/settings/SettingsHeader.vue"),
	},
    data() {
        return {
            transitionName: "",
        };
    },

    watch: {
        '$route.name': {
            handler: function (toRouteName, fromRouteName) {
				let toMainMenu = toRouteName == "calendar-settings-menu"
				let fromMainMenu = fromRouteName == "calendar-settings-menu"
				let rightAnimationPages = [
					'calendar-settings-host',
					'calendar-settings-template',
				]
                
                this.transitionName = toMainMenu || (rightAnimationPages.includes(fromRouteName) && !fromMainMenu) ?  
					'slide-right' : 'slide-left'
            },
            deep: true,
            immediate: true
        },
    },

	beforeRouteLeave (to, from, next) {

		if (to.params.calendarUid != from.params.calendarUid) {
			const futurePageName = this.getFuturePageName(this.$route.name)
			this.$router.replace({
				name: futurePageName,
				params: {
					calendarUid: to.params.calendarUid,
				}
			})
		}

		next()
	},

    computed: {
        isNewCalendar() {
            return this.$route.params.isNewCalendar
        },
		isForm(){
			let forms = [
				'calendar-settings-template'
			]
			return !!forms.find(name => name == this.$route.name)
		},
        calendarSettingsTitle() {
			if (this.isNewCalendar) {
				return "Calendar is created!"
			}
            let settingsName = ""
            switch (this.$route.name) {
                case "calendar-settings-menu":
                    settingsName = this.$route.params.calendarUid
                    if (this.$store.getters.getDefaultCalendar == settingsName)
                        settingsName = 'My calendar'
                    break;
                case "calendar-settings-template-menu":
                    settingsName = "Templates settings"
                    break;
                case "calendar-settings-host-menu":
                    settingsName = "Hosts settings"
                    break;
				case "calendar-settings-host":
					settingsName = "Host"
					break;
				case "calendar-settings-price-level":
                    settingsName = "Event Price Levels"
                    break;
				case "calendar-settings-template":
					settingsName = "Template settings"
					break;
                case "calendar-settings-rating-menu":
                    const calendar = this.$store.getters.currentCalendar()
					if (!calendar)
						return `Event Complexity Levels`
					settingsName = `Event ${calendar.settings.ratingTypeName ?? "Complexity"} Levels`
                    break;
            }
            if (settingsName == "") return undefined

            return settingsName
        }
    },
    methods: {
        goOut() {
			const originPageHosts = JSON.parse(localStorage.getItem('originPage-hosts'))
			if (this.$route.name == 'calendar-settings-host-menu' && originPageHosts) {
				localStorage.removeItem('originPage-hosts')
				this.$router.push(originPageHosts)
				return ;
			}


			const originPageSettings = JSON.parse(localStorage.getItem('originPage-settings'))
			if (this.$route.name == 'calendar-settings-menu' && originPageSettings) {
				localStorage.removeItem('originPage-settings')
				this.$router.push(originPageSettings)
				return ;
			} 

			const futureRouteName = this.getFuturePageName(this.$route.name)
			this.$router.push({
				name: futureRouteName,
				params: {
					calendarUid: this.$route.params.calendarUid
				}
			})
        },

		getFuturePageName(currentRouteName){
			const redirectionArray = [
				{from : 'calendar-settings-menu', to : 'calendar-schedule'},
				{from : 'calendar-settings-host', to : 'calendar-settings-host-menu'},
				{from : 'calendar-settings-template', to : 'calendar-settings-template-menu'},
			]

			const futureRedirection = redirectionArray.find(item => {
				return item.from == currentRouteName
			})
			if (futureRedirection == undefined) {
				return 'calendar-settings-menu'
			}
			return futureRedirection.to
		}
    },
};


</script>

<style scoped>
.calendar_settings { 
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.header.is-form {
	justify-content: space-between;
}

.header-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fade-enter-active {
    animation: fade 0.4s;
}

.fade-leave-active {
    animation: fade 0.4s reverse;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.slide-right-enter-active {
    animation: slide-right-in 0.5s forwards;
    -webkit-animation: slide-right-in 0.5s forwards;
}

.slide-right-leave-active {
    animation: slide-right-out 0.5s forwards;
    -webkit-animation: slide-right-out 0.5s forwards ;
}

.slide-left-enter-active {
    animation: slide-left-in 0.5s forwards;
    -webkit-animation: slide-left-in 0.5s forwards;
}

.slide-left-leave-active {
    animation: slide-left-out 0.5s forwards;
    -webkit-animation: slide-left-out 0.5s forwards ;
}

    
@keyframes slide-right-in {
    100% { transform: translateX(0%); opacity: 1; }
}

@-webkit-keyframes slide-right-in {
    100% { -webkit-transform: translateX(0%); opacity: 1;}
}
    
@keyframes slide-right-out {
    0% { transform: translateX(0%); opacity: 1;}
    100% { transform: translateX(100%); opacity: 0;}
}

@-webkit-keyframes slide-right-out {
    0% { -webkit-transform: translateX(0%); opacity: 1;}
    100% { -webkit-transform: translateX(100%); opacity: 0;}
}

@keyframes slide-left-in {
    100% { transform: translateX(0%); opacity: 1;}
}

@-webkit-keyframes slide-left-in {
    100% { -webkit-transform: translateX(0%); opacity: 1;}
}
    
@keyframes slide-left-out {
    0% { transform: translateX(0%); opacity: 1;}
    100% { transform: translateX(-100%); opacity: 0;}
}

@-webkit-keyframes slide-left-out {
    0% { -webkit-transform: translateX(0%); opacity: 1;}
    100% { -webkit-transform: translateX(-100%); opacity: 0;}
}

.calendar-settings__content{
	margin-top: 32px;
	padding: 24px 16px;
}

</style>